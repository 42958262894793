import React, { useState, useContext } from 'react'
import {
  login
} from '../../utils/api'
import { RootContext } from '../../RootContext'

function UserForm({loginSuccess}) {
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const { setAuthenticated } = useContext(RootContext)

  const onLogin = async () => {
    const res = await login(email, password)
    if (res.status === 200) {
      setAuthenticated(res.data.token)
      if (loginSuccess) loginSuccess(true)
    }
  }

  return (
    <form className="pure-form pure-form-stacked">
      <fieldset>
        <legend>Login</legend>
        <label htmlFor="email">Email/Username</label>
        <input type="email" 
               placeholder="Email" 
               value={email} 
               onChange={e => setEmail(e.target.value)}/>

        <label htmlFor="password">Password</label>
        <input type="password" 
               placeholder="Password"
               value={password}
               onChange={e => setPassword(e.target.value)}></input>

        <button type="button" 
                className="pure-button pure-button-primary"
                onClick={() => onLogin()}>
          Login
        </button>
      </fieldset>
    </form>
  )
}

export default UserForm
