import React from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"
import RootContext from './RootContext'
import Header from './components/header/header'
import Home from './components/home/home'
import Reservation from './components/reservation/reservation'
import Login from './components/login/login'
import AuthenticatedRoute from './components/shared/AuthenticatedRoute'

function App() {
  return (
    <RootContext>
      <Router>
        <div>
          {/* <Header/> */}
          <div style={{"text-align": 'center'}}> Under Construction </div>
          {/* <Route exact path="/" component={Home} />
          <AuthenticatedRoute exact path="/reservation" component={Reservation} />
          <Route exact path="/login" component={Login} /> */}
        </div>
      </Router>
    </RootContext>
  );
}

export default App;
