import React, { useState, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Modal from 'react-modal'
import UserForm from '../shared/UserForm'
import { menu } from 'purecss' // eslint-disable-line
import { RootContext } from '../../RootContext'


Modal.setAppElement('#root')

const LOGGED_IN = 'My Account'
const NOT_LOGGED_IN = 'Login / Sign Up'

function Header({ location }) {
  const { pathname } = location

  const { authenticated } = useContext(RootContext)
  const [ userModal, setUserModal ] = useState(false)
  const toggleModal = () => { setUserModal(!userModal) }
  return (
    <div className="header">
      <div className="home-menu pure-menu pure-menu-horizontal pure-menu-fixed">
        <a className="pure-menu-heading" href="/">CampWatcher</a>

        <ul className="pure-menu-list">
          <li className="pure-menu-item">
            <Link className="pure-menu-link" to="/reservation">My Reservations</Link></li>
          <li className="pure-menu-item">
            <Link className="pure-menu-link" to="/">FAQ</Link></li>
          <li className="pure-menu-item">
            <Link className="pure-menu-link" 
                  to={authenticated ? '/account' : pathname} 
                  onClick={() => toggleModal()}
            >
              {authenticated !== 'false' ? LOGGED_IN : NOT_LOGGED_IN}
            </Link>
          </li>
        </ul>
        <Modal
          style={{
            overlay: {
              zIndex: 9000
            }
          }}
          isOpen={userModal}
          onRequestClose={() => toggleModal()}>
          <UserForm
            loginSuccess={() => toggleModal()}
          />
        </Modal>
      </div>
    </div>
  )
}

export default withRouter(Header)
