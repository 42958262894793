import React, { useEffect, useState } from 'react';

export const RootContext = React.createContext();

export default ({ children }) => {
  const prevAuth = window.localStorage.getItem('jwt') || false;
  const [authenticated, setAuthenticated] = useState(prevAuth);
  useEffect(
    () => {
      window.localStorage.setItem('jwt', authenticated);
    },
    [authenticated]
  );
  const defaultContext = {
    authenticated,
    setAuthenticated
  };
  return (
    <RootContext.Provider value={defaultContext}>
      {children}
    </RootContext.Provider>
  );
};