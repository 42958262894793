import axios from 'axios'

const API = 'http://192.168.0.106:3001'

const getRecArea = async (query) => {
  const res = await axios.get(`${API}/recArea`, {
    params: { name: query },
  })
  return res.data
}

const getRecAreaCampgrounds = async (query) => {
  const res = await axios.get(`${API}/campground`, {
    params: { rec_area_name: query }
  })
  return res.data
}

const login = async (email, password) => {
  try {
    const res = await axios.post(`${API}/user/login`, {email, password})
    return res
  } catch (e) {
    return e.response
  }
}

// Authenticated endpoints
const getJwt = () => {
  return localStorage.getItem('jwt')
}

const getReservations = async () => {
  const res = await axios.get(`${API}/reservation`, {
    headers: {
      'Authorization': `Bearer ${getJwt()}`
    }
  })
  return res.data
}

export {
  getRecArea,
  getRecAreaCampgrounds,
  login,
  getReservations
}